const addPatientScreenMessages = {
    fieldIsRequiredError: 'Field is required',
    invalidInputError: 'Field is invalid',
    createProfile : 'Create Profile',
    stepLabel : 'Step',
    readResultHeader :'Learn how to read the results',
    back : 'Back',
    finish : 'Finish',
    continue : 'Continue',
    imageUploadTitle: 'Let’s take a picture of your device',
    getResults:'Get Covid-19 Records'
};

export default addPatientScreenMessages;