const testRecordStepsMessages = {
  step1FormTitle: 'Tell us about yourself',
  name: 'Full Name',
  dateOfBirth: 'Date of birth',
  sex: 'Gender',
  race: 'Race',
  ethnicity: 'Ethnicity',
  nameError: 'Please enter your full name',
  step2FormTitle: 'Additional details',
  streetAddress: 'Address',
  city: 'City',
  state: 'State',
  zipcode: 'Zipcode',
  zipcodeError: 'Please match the format 99999 or 99999-9999',
  emailAddress: 'Email',
  addressInvalidChars:
    "Please provide the following characters only -> alphanumeric,.'-",
  step3FormTitle: 'Do any of these apply?',
  symptomatic: 'I have symptoms of COVID-19',
  firstTest: 'This is my first test',
  pregnant: 'I am pregnant',
  employedInHealthcare: 'I am employed in healthcare',
  residenceInCongreateCare: 'I live in a congregate facility',
  step4FormTitle: 'Take the test',
  step5FormTitle: 'What was the test result?',
  step6FormTitle: 'Opt-in to contact tracing?',
  negative: 'Negative',
  positive: 'Positive',
  negativeResult: 'SARS-CoV-2 virus not detected',
  positiveResult: 'SARS-CoV-2 virus detected',
  shareData: 'Share my data with state health officials',
  dontShareData: "Don't share my data",
  scanBarcode:
    'Scan the barcode at the back of your drivers license by positioning it inside the box below',
  dataShareInfo:
    'Sharing your test results and contact information helps the government contain outbreaks through contact tracing. By opting in, your name, address, and contact information will be shared with state health officials.',
};

export default testRecordStepsMessages;
