const settingsScreenMessages = {
  settingsText: 'Settings',
  userProfileManagement: 'User profile management',
  privacyText: 'Privacy',
  optionText: 'Opt-in to contact tracing',
  optionDescToggleOn:
    'You have opted into contact tracing. This allows Skypass to share your contact information with state health officials to help them better manage the pandemic. To opt-out, hit the toggle.',
  optionDescToggleOff:
    'You have opted out of contact tracing. This allows Skypass to share your contact information with state health officials to help them better manage the pandemic. To opt-in, hit the toggle.',
  storedData: 'Stored data',
  storedDataDescription:
    'View what information you have shared with Skypass. Skypass does not share your data without your explicit consent. You can also choose to delete all data from Skypass.',
  downloadText: 'Download all data',
  deleteText: 'Delete all data',
  downloadDescription:
    'Download a comprehensive report of all data that you have stored on Skypass.',
  deleteDescription:
    'Delete all information related to this patient on Skypass.',
  downloadActionText: 'Download data',
  deleteActionText: 'Delete data',
};

export default settingsScreenMessages;
