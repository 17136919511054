import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import TestResultCard from '../../molecules/TestResultCard';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { useResults } from './hook';
import ErrorCard from '../../molecules/ErrorCard';
import errorIcon from '../../../../../public/assets/errorStateIcon.svg';
import emptyTests from '../../../../../public/assets/noTests.svg';
import LoadingState from '../../../../common/_components/atoms/LoadingState';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) =>
  createStyles({
    retryButton: {
      width: '159px',
    },
  }),
);

interface ITestResults {
  patientSkyflowId?: string;
  patientName?: string;
}

const TestResults: React.FC<ITestResults> = ({
  patientSkyflowId,
  patientName,
}) => {
  const classes = useStyles();
  const { name, loading, error, tests } = useResults(
    patientSkyflowId,
    patientName,
  );

  if ((!patientSkyflowId || tests.length === 0) && !loading && !error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <ErrorCard
          errorIcon={emptyTests}
          errorInfo="No test records"
          errorDetails=""
          height="400px"
        />
      </Box>
    );
  } else
    return error ? (
      <Box height="100%">
        <ErrorCard errorIcon={errorIcon} height="400px" />
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.retryButton}
            onClick={() => window.location.reload()}
          >
            <FormattedMessage id="retry" />
          </Button>
        </Box>
      </Box>
    ) : (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={4.5}
        mt={10}
      >
        {loading && <LoadingState />}
        <Typography variant="h3">
          <FormattedMessage id="testTimelineHeader" /> {name}
        </Typography>
        <Box display="flex" flexDirection="column" marginTop={8}>
          {tests &&
            tests.map((test, index) => (
              <TestResultCard
                key={index}
                isLast={tests.length == index + 1}
                {...test.result}
              />
            ))}
        </Box>
      </Box>
    );
};

export default TestResults;
