export const KeyCodes = {
  DCA: 'jurisdictionVehicleClass',
  DCB: 'jurisdictionRestrictionCodes',
  DCD: 'jurisdictionEndorsementCodes',
  DBA: 'dateOfExpiry',
  DCS: 'lastName',
  DAC: 'firstName',
  DCT: 'firstName',
  DAD: 'middleName',
  DBD: 'dateOfIssue',
  DBB: 'dateOfBirth',
  DBC: 'sex',
  DAY: 'eyeColor',
  DAU: 'height',
  DAG: 'addressStreet',
  DAI: 'addressCity',
  DAJ: 'addressState',
  DAK: 'addressPostalCode',
  DAQ: 'documentNumber',
  DCF: 'documentDiscriminator',
  DCG: 'issuer',
  DDE: 'lastNameTruncated',
  DDF: 'firstNameTruncated',
  DDG: 'middleNameTruncated',
  DAB: 'familyName',
  DAL: 'residenceStreetAddress',
  DAN: 'residenceCity',
  DAO: 'residenceJurisdictioncord',
  DAP: 'residencePostalCode',
  DAR: 'licenseClassification',
  DAS: 'licenseRestrictionCode',
  DAT: 'licenseEndorsementsCode',
  DBH: 'organDonor',
  DBJ: 'uniqueCustomIdentifier',
  ZAA: 'asd',
  DAA: 'fullName',
  // optional
  DAZ: 'hairColor',
  DAH: 'addressStreet2',
  DCI: 'placeOfBirth',
  DCJ: 'auditInformation',
  DCK: 'inventoryControlNumber',
  DBN: 'otherLastName',
  DBG: 'otherFirstName',
  DBS: 'otherSuffixName',
  DCU: 'nameSuffix', // e.g. jr, sr
  DCE: 'weightRange',
  DCL: 'race',
  DCM: 'standardVehicleClassification',
  DCN: 'standardEndorsementCode',
  DCO: 'standardRestrictionCode',
  DCP: 'jurisdictionVehicleClassificationDescription',
  DCQ: 'jurisdictionEndorsementCodeDescription',
  DCR: 'jurisdictionRestrictionCodeDescription',
  DDA: 'complianceType',
  DDB: 'dateCardRevised',
  DDC: 'dateOfExpiryHazmatEndorsement',
  DDD: 'limitedDurationDocumentIndicator',
  DAW: 'weightLb',
  DAX: 'weightKg',
  DDH: 'dateAge18',
  DDI: 'dateAge19',
  DDJ: 'dateAge21',
  DDK: 'organDonor',
  DDL: 'veteran',
};

export enum states  {
  AL= 'ALABAMA',
  AK= 'ALASKA',
  AZ= 'ARIZONA',
  AR= 'ARKANSAS',
  CA= 'CALIFORNIA',
  CO= 'COLORADO',
  CT= 'CONNECTICUT',
  DE= 'DELAWARE',
  FL= 'FLORIDA',
  GA= 'GEORGIA',
  HI= 'HAWAII',
  ID= 'IDAHO',
  IL= 'ILLINOIS',
  IN= 'INDIANA',
  IA= 'IOWA',
  KS= 'KANSAS',
  KY= 'KENTUCKY',
  LA= 'LOUISIANA',
  ME= 'MAINE',
  MD= 'MARYLAND',
  MA= 'MASSACHUSETTS',
  MI= 'MICHIGAN',
  MN= 'MINNESOTA',
  MS= 'MISSISSIPPI',
  MO= 'MISSOURI',
  MT= 'MONTANA',
  NE= 'NEBRASKA',
  NV= 'NEVADA',
  NH= 'NEW_HAMPSHIRE',
  NJ= 'NEW_JERSEY',
  NM= 'NEW_MEXICO',
  NY= 'NEW_YORK',
  NC= 'NORTH_CAROLINA',
  ND= 'NORTH_DAKOTA',
  OH= 'OHIO',
  OK= 'OKLAHOMA',
  OR= 'OREGON',
  PA= 'PENNSYLVANIA',
  RI= 'RHODE_ISLAND',
  SC= 'SOUTH_CAROLINA',
  SD= 'SOUTH_DAKOTA',
  TN= 'TENNESSEE',
  TX= 'TEXAS',
  UT= 'UTAH',
  VT= 'VERMONT',
  VA= 'VIRGINIA',
  WA= 'WASHINGTON',
  WV= 'WEST_VIRGINIA',
  WI= 'WISCONSIN',
  WY= 'WYOMING',
};
