import React from 'react';

export const getSwitcherStates = () => {
  const [toggleOn, setToggleOn] = React.useState(true);
  const onToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToggleOn(event.target.checked);
  };

  return {
    toggleOn,
    onToggleChange,
  };
};
