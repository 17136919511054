import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useAddPatientSteps } from './hook';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  Typography,
  Link,
} from '@material-ui/core';
import Stepper from '../../molecules/Stepper';
import Form from '../../molecules/Form';
import { useProcedureInfoModal } from '../ProcedureInfo/hook';
import ProcedureInfo from '../ProcedureInfo';
import { BACK_EVENT } from '../../../_utils/events';
import { useParams, useHistory } from 'react-router-dom';
import {
  resultInterpretation,
  videoTutorialEmbedded,
} from '../../../_utils/constants';
import { FormattedMessage, useIntl } from 'react-intl';
import UploadButton from '../../atoms/UploadButton';
import Camera from '../../../../common/_components/molecules/Camera';
import PreviewImage from '../../atoms/PreviewImage';
import theme from '../../../../core-utils/theme';
import UploadIcon from '../../../../common/_components/icons/Upload';
import Capture from '../../../../common/_components/icons/Capture';
import { useAuth } from '../../../../auth/Auth';

const useStyles = makeStyles((theme) =>
  createStyles({
    stepper: {
      position: 'sticky',
      paddingTop: theme.spacing(4),
      top: 0,
      left: 0,
      right: 0,
      zIndex: 10,
    },
    button: {
      margin: theme.spacing(5, 1),
    },
    helperText: {
      minHeight: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface IAddPatientSteps {}

const AddPatientSteps: React.FC<IAddPatientSteps> = () => {
  const classes = useStyles();
  const { id } = useParams<any>();
  const state = useAddPatientSteps(id);
  const { infoModalOpen, toggleInfoModal } = useProcedureInfoModal();
  const handleBackClick = () => {
    window.dispatchEvent(new CustomEvent(BACK_EVENT));
  };
  const { formatMessage } = useIntl();
  const { location } = useHistory();
  const { deviceID } = useAuth();

  return (
    <Box minHeight="100%" width="100%" mt={4} position="relative">
      <Box width="100%" mb={18.5}>
        <Form
          {...state.forms[state.variables.activeStep]}
          handleChange={state.handlers.handleChange.bind(
            null,
            state.variables.activeStep,
          )}
          disabled={state.variables.loading}
          showError={
            location.state &&
            location.state instanceof Object &&
            state.variables.activeStep === 0
              ? true
              : false
          }
        />
        {state.variables.error && (
          <FormHelperText>
            <Typography
              variant="body2"
              color="error"
              className={classes.helperText}
              component="span"
            >
              <Box display="flex" alignItems="center">
                {state.variables.error}
              </Box>
            </Typography>
          </FormHelperText>
        )}
      </Box>
      <Box
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        bgcolor={theme.palette.common.white}
      >
        <Container maxWidth="md">
          <Box display="flex" width="100%" justifyContent="center" px={5}>
            <Button
              variant="contained"
              onClick={state.handlers.onStepClick.bind(null, undefined)}
              color="primary"
              className={classes.button}
              disabled={
                state.variables.submitDisabled || state.variables.loading
              }
              startIcon={
                state.variables.loading && (
                  <CircularProgress color="inherit" size={20} />
                )
              }
            >
              {state.variables.isLastStep ? (
                <FormattedMessage id="proceedMessage" />
              ) : (
                <FormattedMessage id="continue" />
              )}
            </Button>
          </Box>
        </Container>
      </Box>
      <ProcedureInfo
        isOpen={infoModalOpen}
        onClose={toggleInfoModal}
        title={formatMessage({ id: 'resultTitle' })}
        content={resultInterpretation[deviceID]}
        iframeUrl={videoTutorialEmbedded[deviceID]}
        hideIndex
      />
    </Box>
  );
};

export default AddPatientSteps;
