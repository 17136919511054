import * as React from 'react';
import { getVaccinations } from '../../../../services';
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../../../core-utils/helpers';

export const useVaccinations = (patientId) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<any>('');
  const [status, setStatus] = React.useState('');
  const { location } = useHistory<any>();
  const patientSkyflowId = location.state && location.state.skyflowId;
  const patientName = location.state && location.state.name;
  const dob = location.state?.dateOfBirth;
  const obj = {
    name: { name: 'Name', value: patientName },
    dob: { name: 'DOB', value: dob !== undefined ? formatDate(dob) : '' },
    responsibleOrg: { name: 'Vaccine administrator', value: '' },
    doseNum: { name: 'Doses received', value: '' },
    vaxSeriesComplete: { name: 'Series complete', value: '' },
    vaxEffectiveDate: { name: 'Effective date', value: '' },
    vaxExpirationDate: { name: 'Expiration date', value: '' },
  };
  const [result, setResult] = React.useState<any>();
  const [rowCount, setRowCount] = React.useState();
  React.useEffect(() => {
    if (patientId) {
      getVaccinations(patientId)
        .then((res: any) => {
          const vaccinations = { ...obj };

          const resultObj = res.data.rows;
          setRowCount(resultObj.length);
          setStatus(resultObj.status || '');
          Object.keys(resultObj).forEach((x) => {
            if (vaccinations[x])
              if (x.includes('Date')) {
                vaccinations[x].value = formatDate(resultObj[x]);
              } else {
                vaccinations[x].value = resultObj[x];
              }
          });
          setResult(vaccinations);
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return {
    loading,
    error,
    result,
    rowCount,
    status,
  };
};
