export const TERMS = '/terms';
export const PRIVACY = '/privacy';
export const TEST_RESULTS = '/tests-timeline';
export const HOME = '/';
export const VERIFY = '/verify';
export const ADD_PROFILE = '/add-profile/:id?';
export const PROFILES = '/profiles';
export const SCAN_DRIVERS_LICENSE = '/scan-dl';
export const SCAN = '/scan';
export const SETTINGS = '/settings';
export const PRIVACY_SETTINGS = '/privacySettings';
export const STORAGE = '/storage';
export const DOWNLOAD_DATA = '/downloadData';
export const DELETE_DATA = '/deleteData';
export const TESTS = '/tests';
