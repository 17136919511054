import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DefaultTemplate from '../../_components/templates/DefaultTemplate';
import TestsAndVaccinations from '../../_components/organisms/TestsAndVaccinations';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => createStyles({}));

interface ITestsAndVaccinations {}

const TestsAndVaccinationsPage: React.FC<ITestsAndVaccinations> = () => {

  const { location } = useHistory<any>();
  const patientName = location.state && location.state.name;
  
  return (
    <DefaultTemplate body={<TestsAndVaccinations />} additionalText ={patientName}/>
  );
};

export default TestsAndVaccinationsPage;
