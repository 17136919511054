const driversLicenseScanScreen = {
  scanDLScreenHeader: 'Let’s create your profile',
  scanDLScreenSubHeaderText:
    'We need some details to fetch your test and vaccination records',
  scanDLActionText: 'Scan Driver’s License',
  addManuallyActionText: 'Add Manually',
  orText: 'Or',
};

export default driversLicenseScanScreen;
