import CheckCircle from '../common/_components/icons/CheckCircle';
import Consent from '../common/_components/icons/Consent';
import Documentation from '../common/_components/icons/Documentation';
import Person from '../common/_components/icons/Person';
import Pin from '../common/_components/icons/Pin';
import Details from '../common/_components/icons/Details';
import Shield from '../common/_components/icons/Shield';

export const steps = [
  {
    label: 'personal details',
    icon: Person,
  },
  {
    label: 'address',
    icon: Details,
  },
  {
    label: 'health',
    icon: Shield,
  },
  {
    label: 'how to',
    icon: Documentation,
  },
  {
    label: 'results',
    icon: CheckCircle,
  },
  {
    label: 'share',
    icon: Consent,
  },
];

export const OTP_LENGTH = 6;

export const CORONA_INFO_LINK = 'https://www.cdc.gov/coronavirus';

export const ACTION_BUTTON_MAX_WIDTH = 500;

export const COLORS_ARRAY = [
  '#2D7FF6',
  '#4BBFF8',
  '#5FDAD2',
  '#5ECA36',
  '#F7B543',
  '#666666',
];

export const FEATURE_KEYS = {
  scanner: 'scanditScanner',
  imageUpload: 'uploadTestResultImage',
};

export const hasMedia =
  navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia;