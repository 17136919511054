import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import theme from '../../../../core-utils/theme';
import IconDescription from '../IconDescription';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: theme.spacing(10)
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    descriptions: {
      marginTop: theme.spacing(4.5),
    },
  }),
);

interface IDescription {
  descriptions: any[];
}

const Description: React.FC<IDescription> = ({ descriptions }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      {descriptions.map((item, index) => {
        return (
          <div key={index} className={classes.descriptions}>
            <IconDescription
              icon={item.icon}
              description={item.description}
            ></IconDescription>
          </div>
        );
      })}
    </Grid>
  );
};

export default Description;
