import * as React from 'react';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate';
import { Typography, Box, Card } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import PersonIcon from '../../../../../public/assets/personIcon.svg';
import PrivacyIcon from '../../../../../public/assets/privacyIcon.svg';
import theme from '../../../../core-utils/theme';
import history from '../../../../core-utils/history';
import useBack from '../../../_hooks/useBack';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '56px',
      marginLeft: -theme.spacing(7),
      marginRight: -theme.spacing(7),
      cursor: 'pointer',
      boxShadow: theme.shadows[11],
    },
    image: {
      padding: theme.spacing(0, 2, 0, 6),
    },
  }),
);
const Settings = () => {
  const classes = useStyles();
  useBack(() => {
    history.push('/profiles');
  });
  return (
    <DefaultSubTemplate
      header={
        <Typography variant="h1">
          <FormattedMessage id="settingsText" />
        </Typography>
      }
      body={
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          marginTop={theme.spacing(2.5)}
        >
          <Card
            onClick={() => history.push('/privacySettings')}
            className={classes.card}
          >
            <Box
              display="flex"
              alignItems="center"
              height="100%"
              borderBottom="1px solid #efefef"
            >
              <img className={classes.image} src={PrivacyIcon} />
              <Typography variant="subtitle1" color="textPrimary">
                <FormattedMessage id="privacyText" />
              </Typography>
            </Box>
          </Card>
          <Card className={classes.card}>
            <Box
              display="flex"
              alignItems="center"
              height="100%"
              borderBottom="1px solid #efefef"
            >
              <img className={classes.image} src={PersonIcon} />
              <Typography variant="subtitle1" color="textPrimary">
                <FormattedMessage id="userProfileManagement" />
              </Typography>
            </Box>
          </Card>
        </Box>
      }
    />
  );
};

export default Settings;
