import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Container,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { BACK_EVENT } from '../../../_utils/events';
import theme from '../../../../core-utils/theme';
import SettingsIcon from '../../../../../public/assets/settings.svg';
import history from '../../../../core-utils/history';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    backButton: {
      marginLeft: '-12px',
    },
    app: {
      padding: theme.spacing(0, 5),
      height: '100%',
    },
    imageText: {
      display: 'none',
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
    header: {
      background: theme.palette.background.default,
    },
  }),
);

interface IDefaultTemplate {
  header?: any;
  body: any;
  showBackButton?: any;
  showSettings?: any;
  additionalText?: any;
}

const DefaultTemplate: React.FC<IDefaultTemplate> = ({
  header,
  body,
  showBackButton,
  showSettings,
  additionalText,
}) => {
  const classes = useStyles();

  const handleBackClick = () => {
    window.dispatchEvent(new CustomEvent(BACK_EVENT));
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Box display="flex" className={classes.app} flexDirection="column">
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          zIndex={2}
          className={classes.header}
        >
          {showBackButton !== false && (
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              height="44px"
              padding="0px 28px"
              marginTop={theme.spacing(1)}
              justifyContent="space-between"
            >
              <IconButton
                aria-label="back"
                className={classes.backButton}
                onClick={handleBackClick}
              >
                <NavigateBefore />
              </IconButton>
              {additionalText && (
                <Box marginRight="auto">
                  <Typography variant="subtitle1">{additionalText}</Typography>
                </Box>
              )}
              {showSettings === true && (
                <Link onClick={() => history.push('/settings')}>
                  <Tooltip title={<FormattedMessage id="settingsText" />}>
                    <img src={SettingsIcon} width="20px" height="20px" />
                  </Tooltip>
                </Link>
              )}
            </Box>
          )}
        </Box>
        {header && <Box>{header}</Box>}
        <Box
          flex="auto"
          marginBottom={theme.spacing(1)}
          marginTop={theme.spacing(3.5)}
        >
          {body}
        </Box>
      </Box>
    </Container>
  );
};

export default DefaultTemplate;
