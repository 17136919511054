import * as React from 'react';
import {
  Box,
  Tabs,
  Tab,
  makeStyles,
  Card,
  Typography,
  Button,
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import Stamp from '../../../../../public/assets/stamp.png';
import StatusChip from '../../atoms/StatusChip';
import theme from '../../../../core-utils/theme';
import TestResults from '../TestResults/index';
import useBack from '../../../_hooks/useBack/index';
import history from '../../../../core-utils/history';
import { useHistory } from 'react-router-dom';
import ErrorCard from '../../molecules/ErrorCard';
import emptyVaccinations from '../../../../../public/assets/noVaccinations.svg';
import { useVaccinations } from './hook';
import LoadingState from '../../../../common/_components/atoms/LoadingState';
import errorIcon from '../../../../../public/assets/errorStateIcon.svg';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      width: 'inherit',
    },
    flexContainer: {
      justifyContent: 'center',
    },
    indicator: {
      width: '100%',
    },
    tab: {
      width: '50%',
    },
    chip: {
      paddingLeft: '8px',
    },
    retryButton: {
      width: '159px',
    },
    wrapper: {
      fontWeight: 500,
    },
  }),
);
const TestsAndVaccinations = () => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useBack(() => {
    history.push('/profiles');
  });

  const { location } = useHistory<any>();
  const patientSkyflowId = location.state && location.state.skyflowId;
  const patientName = location.state && location.state.name;

  const { loading, error, result, rowCount, status } = useVaccinations(
    location.state?.skyflowId,
  );
  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Box
        display="flex"
        justifyContent="center"
        marginLeft="-28px"
        marginRight="-28px"
      >
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          className={classes.indicator}
          classes={{ flexContainer: classes.flexContainer }}
        >
          <Tab
            label="Vaccinations"
            classes={{ root: classes.root, wrapper: classes.wrapper }}
            className={classes.tab}
          />
          <Tab
            label="Tests"
            classes={{ root: classes.root, wrapper: classes.wrapper }}
            className={classes.tab}
          />
        </Tabs>
      </Box>
      {value === 0 &&
        (location.state === undefined || rowCount === 0) &&
        !loading && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <ErrorCard
              errorIcon={emptyVaccinations}
              errorInfo="No vaccinations found                                                                                                                                                                                                                                                                                                                                                                  "
              errorDetails=""
              height="400px"
            />
          </Box>
        )}
      {error && value === 0 ? (
        <>
          <Box height="100%">
            <ErrorCard errorIcon={errorIcon} height="400px" />
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.retryButton}
                onClick={() => window.location.reload()}
              >
                <FormattedMessage id="retry" />
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <>
          {loading && <LoadingState />}
          {value === 0 &&
            location.state &&
            result &&
            !loading &&
            rowCount !== 0 && (
              <Box>
                <Box marginTop="30px" display="flex" justifyContent="center">
                  <img
                    src={Stamp}
                    style={{ width: '116px', height: '116px' }}
                  />
                </Box>
                <Box marginTop="30px" display="flex" justifyContent="center">
                  <Box minWidth="130px">
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      style={{ marginRight: '8px' }}
                    >
                      Traveller Status :
                    </Typography>
                  </Box>
                  <StatusChip
                    label={status}
                    color={
                      status === 'CLEARED'
                        ? theme.palette.success.main
                        : theme.palette.error.main
                    }
                  />
                </Box>
                <Card
                  style={{
                    overflowX: 'auto',
                    padding: '14px',
                    marginTop: '30px',
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    width="fit-content"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      {Object.keys(result).map((detail) => {
                        return (
                          <Box display="flex" padding="4px">
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              style={{ minWidth: '162px' }}
                            >
                              {result[detail].name}:
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {result[detail].value}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Card>
              </Box>
            )}
        </>
      )}

      {value === 1 && (
        <Box flex="1 0 0">
          <TestResults
            patientSkyflowId={patientSkyflowId}
            patientName={patientName}
          />
        </Box>
      )}
    </Box>
  );
};

export default TestsAndVaccinations;
