import React from 'react';
import { useHistory } from 'react-router-dom';
import history from '../../../../core-utils/history';
import { getTests } from '../../../../services';
import useBack from '../../../_hooks/useBack';

interface RESULT {
  date: string;
  value: string;
}

interface RESULTITEM {
  result: RESULT;
  image?: string;
}

export const useResults = (patientSkyflowId, patientName) => {
  const [results, setResults] = React.useState([] as RESULTITEM[]);
  const [name, setName] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<any>('');
  const patientId = patientSkyflowId;
  const tempName = patientName;


  React.useEffect(() => {
    if (patientId && tempName) {
      setName(tempName);

      getTests(patientId)
        .then((res) => {
          setResults(
            res.data.rows.sort((a, b) => {
              return (
                new Date(b.result.date).valueOf() -
                new Date(a.result.date).valueOf()
              );
            }),
          );
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } 
  }, []);

  return {
    name,
    loading,
    error,
    tests: results,
  };
};
