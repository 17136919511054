import { Box, Button } from '@material-ui/core';
import React from 'react';
import CDCIcon from '../../../../public/assets/cdc.png';
import DHHSIcon from '../../../../public/assets/dhhs.png';
import FDAIcon from '../../../../public/assets/fda.png';
import HeartIcon from '../../../../public/assets/heart.svg';
import LockIcon from '../../../../public/assets/lock.svg';
import OutlineIcon from '../../../../public/assets/outline.svg';
import { useAuth } from '../../../auth/Auth';
import history from '../../../core-utils/history';
import useQueryParam from '../../../core-utils/hooks/useQueryParam';
import Description from '../../_components/molecules/Description';
import Footer from '../../_components/molecules/Footer';
import ProductIcons from '../../_components/molecules/ProductIcons';
import Title from '../../_components/molecules/Title';
import DefaultTemplate from '../../_components/templates/DefaultTemplate';
import { useIntl, FormattedMessage } from 'react-intl';

export interface IWelcome {}

const productIcons = [FDAIcon, DHHSIcon, CDCIcon];

const Welcome: React.FC<IWelcome> = () => {
  const { param: deviceID } = useQueryParam('deviceID');
  const { setDeviceID } = useAuth();
  const { formatMessage } = useIntl();

  const iconDescriptions = [
    {
      icon: HeartIcon,
      description: formatMessage({ id: 'appHelpText' }),
    },
    {
      icon: OutlineIcon,
      description: formatMessage({ id: 'anonymityDescription' }),
    },
    {
      icon: LockIcon,
      description: formatMessage({ id: 'securityDescription' }),
    },
  ];

  React.useEffect(() => {
    setDeviceID('');
  }, []);

  return (
    <DefaultTemplate
      showBackButton={false}
      body={
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
        >
          <Box width="100%">
            <ProductIcons icons={productIcons} />
          </Box>
          <Box py={4}>
            <Title
              title={formatMessage({ id: 'welcomeText' })}
              subTitle={formatMessage({ id: 'appDescription' })}
            ></Title>
            <Description descriptions={iconDescriptions}></Description>
          </Box>
          <Box pb={4} pt={2.5} display="flex" flexDirection="column">
            <Button
              variant="contained"
              onClick={() => {
                history.push('/verify');
              }}
              color="primary"
              style={{ width: 320 }}
            >
              <FormattedMessage id="getStarted" />
            </Button>

            <Footer deviceID={deviceID} />
          </Box>
        </Box>
      }
    />
  );
};

export default Welcome;
