import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate';
import { Typography, Box, Button } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import history from '../../../../core-utils/history';
import theme from '../../../../core-utils/theme';
import useBack from '../../../_hooks/useBack/index';
import { STORAGE } from '../../../../core-utils/routes';

const useStyles = makeStyles((theme) =>
  createStyles({
    error: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
  }),
);

interface DeleteData {}

const DeleteData: React.FC<DeleteData> = () => {
  useBack(() => {
    history.push(STORAGE, true);
  });
  const classes = useStyles();

  return (
    <React.Fragment>
      {
        <DefaultSubTemplate
          header={
            <Typography variant="h1">
              <FormattedMessage id="deleteText" />
            </Typography>
          }
          subHeader={
            <Typography variant="subtitle1" color="textSecondary">
              <FormattedMessage id="deleteDescription" />
            </Typography>
          }
          footerHeight={48}
          body={<Box />}
          actionText={<FormattedMessage id="deleteActionText" />}
          actionProps={{ classes: { root: classes.error } }}
        />
      }
    </React.Fragment>
  );
};

export default DeleteData;
