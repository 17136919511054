const globalMessages = {
  continue:'Continue',
  defaultErrorMsg : 'Oh Snap! Something went wrong.',
  defaultErrorDetailMsg : 'Please check your internet connection or try again later.',
  retry : 'Retry',
  loadingMessage : 'Warming up the processor....',
  checkInternetErrorMsg : 'Oh Snap! Something went wrong. Please try checking both wifi/4G or try again later.',
  noimage : 'Image upload failed, Please try again'
};

export default globalMessages;
