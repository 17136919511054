import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DefaultTemplate from '../../_components/templates/DefaultTemplate';
import AddPatientSteps from '../../_components/organisms/AddPatientSteps';

const useStyles = makeStyles((theme) => createStyles({}));

interface IAddPatientDetails {}

const AddPatientDetails: React.FC<IAddPatientDetails> = () => {
  return <DefaultTemplate body={<AddPatientSteps />} />;
};

export default AddPatientDetails;
