import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate';
import { Typography, Box, Button } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import history from '../../../../core-utils/history';
import theme from '../../../../core-utils/theme';
import useBack from '../../../_hooks/useBack/index';
import { STORAGE } from '../../../../core-utils/routes';
import RadioButtonList from '../../molecules/RadioButtonList';
import { downloadOptions } from '../../../_utils/constants';
import { getDownloadFormat } from './hook';

interface DownloadData {}

const DownloadData: React.FC<DownloadData> = () => {
  useBack(() => {
    history.push(STORAGE, true);
  });
  const { formatMessage } = useIntl();
  const state = getDownloadFormat();

  return (
    <React.Fragment>
      {
        <DefaultSubTemplate
          header={
            <Typography variant="h1">
              <FormattedMessage id="downloadText" />
            </Typography>
          }
          subHeader={
            <Typography variant="subtitle1" color="textSecondary">
              <FormattedMessage id="downloadDescription" />
            </Typography>
          }
          footerHeight={48}
          body={
            <Box width="100%" mb={18.5}>
              <RadioButtonList
                inputs={downloadOptions.inputs.map((input) => ({
                  ...input,
                  content: formatMessage({ id: input.labelKey }),
                  subLabel: undefined,
                }))}
                onChange={state.handleChange}
              />
            </Box>
          }
          actionText={<FormattedMessage id="downloadActionText" />}
          actionProps={{
            disabled: state.disableDownloadButton,
          }}
        />
      }
    </React.Fragment>
  );
};

export default DownloadData;
