const welcomeScreenMessages = {
  welcomeText: 'Welcome to Skypass',
  appDescription: 'COVID-19 Test Reporting App',
  getStarted: 'View Your Tests and Vaccinations',
  appHelpText:
    'This app will help you take your Covid-19 test and anonymously report your results to health officials.',
  anonymityDescription:
    'Your anonymized test results will help public health officials respond faster to the COVID-19 pandemic.',
  securityDescription:
    'Your personal information is secure with us and will not be shared with anyone without your permission.',
  privacyPolicy: 'PRIVACY POLICY',
  termsOfService: 'TERMS OF SERVICE',
  poweredBy: 'Powered by Skyflow',
  and: ' & ',
  viewTestsHistory: 'View Your Tests and Vaccinations',
};

export default welcomeScreenMessages;
