import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import { AnimatedSwitch } from 'react-router-transition';
import theme from './core-utils/theme';
import history from './core-utils/history';
import { Route, Router } from 'react-router-dom';
import {
  ADD_PROFILE,
  HOME,
  PRIVACY,
  TERMS,
  VERIFY,
  PROFILES,
  TEST_RESULTS,
  SCAN_DRIVERS_LICENSE,
  SCAN,
  SETTINGS,
  PRIVACY_SETTINGS,
  STORAGE,
  DOWNLOAD_DATA,
  DELETE_DATA,
  TESTS,
} from './core-utils/routes';
import VerifyUser from './home/_pages/VerifyUser';
import Welcome from './home/_pages/Welcome';
import ThankYou from './home/_pages/ThankYou';
import RegisterDevicePage from './home/_pages/RegisterDevicePage';
import TermsAndPrivacy from './home/_pages/TermsAndPrivacy';
import SecureRoute from './auth/SecureRoute';
import AddPatient from './home/_pages/AddPatient';
import AuthProvider from './auth/AuthProvider';
import TestResults from './home/_components/organisms/TestResults';
import { IntlProvider } from 'react-intl';
import messages from './core-utils/messages';
import ScanDriversLicensePage from './home/_pages/ScanDriversLicensePage';
import ScannerPage from './home/_components/organisms/ScannerPage';
import SettingsPage from './home/_pages/Settings';
import PrivacyPage from './home/_pages/Privacy';
import StoragePage from './home/_pages/Storage';
import DownloadsPage from './home/_pages/DownloadsPage';
import DeleteDataPage from './home/_pages/DeleteDataPage';
import TestsAndVaccinationsPage from './home/_pages/TestsAndVaccinations';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import properties from './core-utils/properties';

const Patients = React.lazy(() => import('./home/_pages/Patients'));
const DeviceInfo = React.lazy(
  () => import('./home/_components/organisms/DeviceInfo'),
);

const App: React.FC<{}> = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline>
        <IntlProvider
          locale={'en' || navigator.language}
          messages={messages['en']}
        >
          <LDProvider
            clientSideID={properties.LD_CLIENT_ID}
            user={{ key: 'firstvitals' }}
          >
            <React.Suspense fallback="">
              <AuthProvider>
                <Router history={history}>
                  <AnimatedSwitch
                    atEnter={{ opacity: 0 }}
                    atLeave={{ opacity: 0 }}
                    atActive={{ opacity: 1 }}
                    className="switch-wrapper"
                  >
                    <SecureRoute
                      exact
                      path={TESTS}
                      component={TestsAndVaccinationsPage}
                    />
                    <SecureRoute
                      exact
                      path={SETTINGS}
                      component={SettingsPage}
                    />
                    <SecureRoute exact path={STORAGE} component={StoragePage} />
                    <SecureRoute
                      exact
                      path={PRIVACY_SETTINGS}
                      component={PrivacyPage}
                    />
                    <SecureRoute
                      exact
                      path={DOWNLOAD_DATA}
                      component={DownloadsPage}
                    />
                    <SecureRoute
                      exact
                      path={DELETE_DATA}
                      component={DeleteDataPage}
                    />
                    <SecureRoute path={ADD_PROFILE} component={AddPatient} />
                    <SecureRoute path={PROFILES} component={Patients} />
                    <SecureRoute path={TEST_RESULTS} component={TestResults} />
                    <Route exact path={VERIFY} component={VerifyUser} />
                    <Route
                      path={[TERMS, PRIVACY]}
                      component={TermsAndPrivacy}
                    />
                    <SecureRoute
                      exact
                      path={SCAN_DRIVERS_LICENSE}
                      component={ScanDriversLicensePage}
                    />
                    <SecureRoute exact path={SCAN} component={ScannerPage} />
                    <Route path={HOME} component={Welcome} />
                  </AnimatedSwitch>
                </Router>
              </AuthProvider>
            </React.Suspense>
          </LDProvider>
        </IntlProvider>
      </CssBaseline>
    </MuiThemeProvider>
  );
};

export default App;
