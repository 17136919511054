import React from 'react';

export const getDownloadFormat = () => {
  const [disableDownloadButton, setDownloadButton] = React.useState(true);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDownloadButton(false);
  };

  return {
    disableDownloadButton,
    handleChange,
  };
};
