import * as React from 'react';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate';
import { Typography, Box, Card } from '@material-ui/core';
import theme from '../../../../core-utils/theme';
import Switcher from '../../molecules/Switch/index';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import useBack from '../../../_hooks/useBack';
import history from '../../../../core-utils/history';
import { FormattedMessage } from 'react-intl';
import { getSwitcherStates } from './hook';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '56px',
      marginLeft: -theme.spacing(7),
      marginRight: -theme.spacing(7),
      cursor: 'pointer',
      boxShadow: theme.shadows[11],
    },
    image: {
      padding: theme.spacing(0, 2, 0, 6),
    },
    text: {
      marginTop: theme.spacing(4),
    },
  }),
);

const Privacy = () => {
  const classes = useStyles();
  const state = getSwitcherStates();
  useBack(() => {
    history.push('/settings');
  });
  return (
    <>
      <DefaultSubTemplate
        header={
          <Typography variant="h1">
            <FormattedMessage id="privacyText" />
          </Typography>
        }
        body={
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            marginTop={theme.spacing(2.5)}
          >
            <Card className={classes.card}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                height="100%"
                borderBottom="1px solid #dfe3eb"
                padding="28px"
              >
                <Typography variant="subtitle1" color="textPrimary">
                  <FormattedMessage id="optionText" />
                </Typography>
                <Switcher
                  checkedState={state.toggleOn}
                  onToggleChange={state.onToggleChange}
                />
              </Box>
            </Card>
            {state.toggleOn ? (
              <Typography
                className={classes.text}
                variant="subtitle1"
                color="textSecondary"
              >
                <FormattedMessage id="optionDescToggleOn" />
              </Typography>
            ) : (
              <Typography
                className={classes.text}
                variant="subtitle1"
                color="textSecondary"
              >
                <FormattedMessage id="optionDescToggleOff" />
              </Typography>
            )}

            <Card
              className={classes.card}
              style={{
                marginTop: theme.spacing(6),
              }}
              onClick={() => history.push('/storage')}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                height="100%"
                borderBottom="1px solid #dfe3eb"
                padding="28px"
              >
                <Typography variant="subtitle1" color="textPrimary">
                  <FormattedMessage id="storedData" />
                </Typography>
                <NavigateNextOutlinedIcon
                  style={{ fill: theme.palette.grey[300] }}
                />
              </Box>
            </Card>
            <Typography
              className={classes.text}
              variant="subtitle1"
              color="textSecondary"
            >
              <FormattedMessage id="storedDataDescription" />
            </Typography>
          </Box>
        }
      />
    </>
  );
};

export default Privacy;
