import * as React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import theme from '../../../../core-utils/theme';

const useStyles = makeStyles((theme) => createStyles({}));

interface IStatusChip {
  label: string;
  color: string;
}
const StatusChip: React.FC<IStatusChip> = ({ label, color }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      color={theme.palette.common.white}
      bgcolor={color}
      borderRadius="3.6px"
      py={0.25}
      px={1.2}
      height='fit-content'
    >
      <Typography variant="overline" color="inherit">
        {label}
      </Typography>
    </Box>
  );
};

export default StatusChip;
